.degree-container {
  height: 100%;
  overflow: scroll;
  :global(.arco-card-body) {
    padding: 16px 0 16px 16px;
  }
}
.tip-container {
  margin-left: -16px;
  margin-top: -16px;
  .wechatBind {
    min-height: 500px;
    padding: 12 12;
    .banner {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.form-container {
  width: 400px;
  .btn-wrap {
    margin-left: 81px;
    width: 224px;
    display: flex;
    justify-content: space-between;
  }
  label {
    display: block;
    width: 80px;
    text-align: left;
  }
  .item-container {
    display: flex;
    .upload-container {
      transform: translateY(-10px);
      .image {
        width: 200px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: rgba(201,205,212);
        background-color: var(--color-fill-2);
        :global(.arco-image-img) {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .example {
      display: flex;
      margin-left: 10px;
      margin-top: 5px;
      .title {
        width: 50px;
        text-align: center;
      }
      .image-container {
        transform: translate(10px);
        border: 1px solid var(--border-color);
        border-radius: 5px;
        width: 144px;
        height: 81px;
      }
      .image {
        :global(.arco-image-img) {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .tip-container {
    position: absolute;
    left: 600px;
    border: 1px solid var(--border-color);
    padding: 10px;
    top: 120px;
    color: var(--color-text3);
    .tip-title {
    }
    .tip-content {
      line-height: 1.7;
    }
  }
}
.paddingTop {
  padding-top: 100px;
}
.imgmodal {
  width: 800px;
  height: 550px;
  .imgwrap {
    width: 740px;
    display: flex;
    justify-content: center;
    height: 400px;
    .imgcontent {
      max-width: 700px;
      max-height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .img {
        // min-width: 100px;
        // min-height: 100px;
        max-width: 700px;
        max-height: 400px;
      }
    }
  }
}
.upload {
  .upload-content {
    // width: 224px;
  }
}
