@height:50px;
.link{
  //position: absolute;
  //padding: 5px;
  //height: auto;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  color: #333;
  padding-left: 10px;
  font-size: 13px;
  height: @height;
  min-width: 400px;
}
.cover{
  width: @height;
  height: @height;
  margin-left: -10px;
  margin-right: 10px;
  flex-shrink: 0;
  object-fit: cover;
  padding: 5px;
  box-sizing: border-box;
  border-radius: var(--border-radius-middle);
}
.icon{
  margin-left: -10px;
  //margin-right: 10px;
  padding: 0 22px;
  color: #1890ff;
  flex-shrink: 0;
  font-size: 16px;
}
.content{
  overflow: hidden;
  /*文本不会换行*/
  white-space: nowrap;
  /*当文本溢出包含元素时，以省略号表示超出的文本*/
  text-overflow: ellipsis;
}
