.content{
  text-decoration: none;
  //white-space: break-spaces;
  :global(div.arco-typography){
    margin-bottom: 5px;
  }
  &.inFeed{
    cursor: pointer;
  }

  .desc{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  :global(.arco-btn div.arco-typography){
    margin-bottom: 0;
  }
  .link{
    //position: absolute;
    //padding: 5px;
    //height: auto;
    display: flex;
    width: fit-content;
    max-width: 100%;
    color: #333;
    padding-left: 10px;
    font-size: 13px;
  }
  .icon{
    margin-right: 10px;
    color: #1890ff;
  }
}
