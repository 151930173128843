.messageList{
  height: 100%;
  overflow-y: scroll;
  padding: 0 20px;
  .tips{
    font-size: 14px;
    color:var(--tip-color);
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 20px;
  }
}
