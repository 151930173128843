.subscription{
  :global(.arco-list-item-extra-content){
    order:-1;
  }
  .cover{
    width: 150px;
    margin:0  20px;
  }
  .title{
    font-size: 20px;
  }
}
