.userInfo{
  height: 100%;
  display: flex;
  justify-content: flex-end;
  .unlogin{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .login{
    display: flex;
    //width: 300px;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .notify{
      .icon{
        font-size: 20px;
        color: #666666;
        margin-top: 5px;
      }
    }
    .creation{
      margin-left: 20px;
      margin-right: 20px;
    }
    .userWrap{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .avatar{
      border: solid 1px #666;
      background: #fff;
    }
    .username{
      padding-left: 10px;
      display: inline-block;
      width: 100px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

