.userInfo{
  height: 100%;
  .unlogin{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .login{
    display: flex;
    width: 280px;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .notify{
      .icon{
        font-size: 20px;
        color: #666666;
      }
    }
    .creation{
      margin-left: 20px;
      margin-right: 20px;
    }
    .avatar{
      border: solid 1px #666;
      background: #fff;
    }
    .username{
      padding-left: 10px;
    }
  }
}

