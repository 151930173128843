.avatar_wrap {
  position: relative;
  .v {
    width: 40%;
    height: 40%;
    position: absolute;
    bottom: 0px;
    right: -5%;
  }
}
