.postPage {
  margin: var(--padding-small) var(--padding-large);
  //max-width: 1000px;
  .post {
    border-radius: var(--border-radius-small);
    background: #fff;
    padding: var(--padding-small);
    :global(.arco-comment-title-align-right) {
      margin: 5px 0 10px 0;
      :global(.arco-comment-author) {
        font-size: 16px;
      }
    }
    .content {
      padding: var(--padding-small) 0;
    }
    .author {
      margin: 0 10px;
    }
    .tag {
      font-size: 14px;
      color: #aaaaaa;
    }
    .commentWrap {
      margin-top: 20px;
      .inputWrap {
        display: flex;
        .avatar {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
      .btnWrap {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
      }
    }
  }
  .about_writer{
    min-width: 230px;
    border-radius: var(--border-radius-small);
  }
  .share {
    min-width: 230px;
    margin-top: var(--padding-small);
    border-radius: var(--border-radius-small);
    .qr {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}
