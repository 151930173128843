.leaderboard{
  min-height: 240px;
  min-width: 260px;
  margin-bottom: 10px;
  :global(.arco-card-body){
    padding-top: 5px;
  }
  .list{
    max-height: 400px;
    overflow-y: scroll;
    .line{
      display: flex;
      align-items: center;
      .num{
        font-size: 20px;
        font-weight: bold;
        margin-right: 5px;
        flex-shrink: 0;
        width: 25px;
        .emoji{
          font-size: 24px;
          margin-left: -5px;
        }
      }
    }
  }


}
