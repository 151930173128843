.user {
  .banner{
    margin-left: -12px;
    width: calc(100% + 12px);
  }
  .header {
    display: block;
    background-color: #fff;
    border-radius: var(--border-radius-small);
    margin-bottom: 10px;
    margin-left: -12px;
    padding: 10px;
    background-image: url("https://images.redream.cn/upic/2019/20220618175348-Success (1).png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    //height: 150px;
    .left {
      display: flex;
      margin: 20px;
      .avatar {
        //border: solid #999 2px;
      }
    }

    .right {
      margin-left: 20px;
      width: 70%;
      display: block;
      .username {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .introduce {
        margin-bottom: 10px;
      }
      :global(.arco-typography-edit-content) {
        background: transparent !important;
      }
      .info {
        display: flex;
        align-items: center;
        color: var(--color-text-3);
        font-size: 12px;
        .tag {
          //margin: 0 5px;
        }
      }
      .uncertified {
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: var(--color-text-3);
        display: flex;
        align-items: center;
        .helper {
          margin: 0 5px;
        }
      }
      .certified {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: var(--color-text-3);
        .weight {
          margin-right: 10px;
          margin-left: 5px;
        }
        .vv {
          width: 18px;
          height: 18px;
        }
        .tag {
          background-color: var(--color-fill-2);
          border-radius: 20px;
          padding: 2px 10px;
          margin: 0 5px;
          color: var(--color-text-2);
        }
      }
    }
  }
  .main {
    background: #fff;
    border-radius: var(--border-radius-small);
  }
  .achievement {
    border-radius: var(--border-radius-small) !important;
  }
}
