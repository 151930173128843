.creation{
  background: #fff;
  padding: var(--padding-middle);
  .img{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height: 300px;
  }
  .rule{
    text-decoration: underline;
    color: rgb(var(--primary-6));
  }
  .titleInput{
    :global(.arco-input-group-addbefore){
      font-size: 0;
      padding: 0;
      border: none;
    }
  }
  .cover{
    width: 40px;
    height: 40px;
    padding: 2px;
    box-sizing: border-box;
    object-fit: contain;
  }
  .coverBox{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
