.company {
  background: transparent;
  min-width: 270px;
  a {
    line-height: 1.8;
  }
  a span {
    display: inline-block;
    width: 80px;
    white-space: nowrap;
  }
}
