.tabtitle {
  font-size: 16px;
  .count {
    color: #999;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 6px;
  }
}
