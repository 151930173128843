.title{
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  //padding-left: 80px;
}

.jump{
  display: flex;
  justify-content: center;
}

.alert{
  margin-bottom: 10px;
  padding-left: 10px;
}
.btn{
  min-width: 100px;
  margin-left: 20px;
}
