.header{
  box-shadow: 0 1px 6px #0000001a;
  padding-left: var(--padding-middle);
  padding-right: var(--padding-middle);
  background: var(--bg-color-white);
  .logo{
    display: block;
    width: 260px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  :global(.arco-menu-inner){
    padding: 14px 0px;
  }
  :global(.arco-menu-pop-header){
    padding: 0 8px;
  }
  .right{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
