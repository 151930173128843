.message {
  height: 100%;
  overflow: hidden;
  .row,
  .col {
    height: 100%;
  }
  .sideMenu {
    height: 100%;
  }
}
//:global(.is-error){
//  border: solid 1px red;
//}
