.feedback {
}
.feedback_modal {
  margin-top: -10px;
  .title {
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
    .title_tip {
      color: red;
      margin-right: 5px;
    }
  }

  .feedback_upload {
    display: flex;
    flex-wrap: wrap;
    :global(.arco-upload-list-type-picture-card) {
      width: auto;
    }
  }
  .feedback_radios {
    margin-top: -10px;
  }
}
.btn {
  padding: 5px;
}
.textarea {
  margin-bottom: 5px;
}
