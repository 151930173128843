.account_delete {
  height: 100%;
  overflow: scroll;
  .container {

    display: flex;
    flex-direction: column;
    font-size: 13px;
    background: #fff;
    margin-bottom: 20px;
    flex-wrap: wrap;
    text-overflow: ellipsis;
    justify-content: space-between;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div {
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      span {
        display: inline-block;
        line-height: 25px;
        text-indent: 16px; /* 设置首行缩进*/
      }
      .inline_strong_font {
        display: inline;
        font-weight: 500;
        color: rgb(29, 33, 41);
      }
      .font_strong {
        font-weight: 500;
        color: rgb(29, 33, 41);
        text-indent: 16px;
      }
    }

    .button_container {
        margin: 20px;
    }
  }
}

