.listWrapper{
  height: 100%;
  overflow: scroll;
}
.list{
  background: #fff;

  .listItem{
    padding: 20px;
    border-bottom: 1px solid var(--color-fill-3);
    .messageText{
      white-space: break-spaces;
      background: #f1f1f1;
      padding: 5px 10px;
      margin: 5px 0;
      border-radius: 5px;
      a{
        color: rgba(var(--primary-6));
      }
    }
  }
}
