.container{
  background: #fff;
  //padding: var(--padding-small);
  margin: var(--padding-small) 10%;

  display: flex;
  height: 80vh;
  padding: 0;
  .left{
    height: 100%;
    max-height: 100%;
    width: 300px;
    flex-shrink: 0;
    border-left: solid 1px #eee;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
    //overflow: scroll;
    padding-bottom: 10px;
    .header{
      height: 40px;
      flex-shrink: 0;
      border-bottom: solid 1px #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      font-size: 16px;
      box-sizing: border-box;
    }
    .list{
      height: calc(100% - 40px);
    }
  }
  .right{
    height: 100%;
    max-height: 100%;
    position: relative;
    flex-grow: 1;
    border: solid 1px #eee;
    overflow: hidden;

    .chatWindow{
      display: flex;
      flex-direction: column;
      height: 100%;
      .chatHeader{
        height: 40px;
        flex-shrink: 0;
        border-bottom: solid 1px #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .more{
          width: 20px;
          cursor: pointer;
        }
      }
      .messages{

        //border: solid 1px #894;
        max-height: calc(100% - 220px);
        height: calc(100% - 220px);
        //background: #f9f9f9;
        flex-grow: 0;
      }
      .footer{
        height: 200px;
        flex-shrink: 0;
        border-top: solid 1px #eee;
        box-shadow: rgba(99, 99, 99, 0.1) 0px -5px 10px -5px;
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        .textarea{
          width: 100%;
          height: 120px;
          background: #ffff;
          border: none;
          resize:none;
        }
        .btnLine{
          display: flex;
          justify-content: flex-end;
          .tips{
            color:#aaaaaa;
            font-size: 14px;
          }
          .btn{
            margin: 0 20px 10px 10px;
            width: 100px;
          }
        }
      }
    }
    .empty{
      background-image: url("https://meikan-public-images.oss-cn-beijing.aliyuncs.com/imeikan/assets/2023-05-03144615-message.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 200px ;
      width: 100%;
      height: 100%;

    }

  }
  .conversation{
    padding: 10px;
    border-bottom: solid 0.5px #eee;
    cursor: pointer;
    &.pin{
      background-color: #f9f9f9;
    }
    &.selected{
      background-color: var(--color-fill-2);
    }

    .lastMessage{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--tip-color);
    }
  }
}

.drawer{
  width: 300px;
  .line{
    display: flex;
    justify-content: space-between;
    border-bottom: solid 0.5px #eee;
    padding: 10px 0;
    .label{

    }
  }
}
