.wechatBind {
  min-height: 400px;
  height: 100%;
  padding: 12 12;
  .banner {
    position: absolute;
    left: 0;
    top: 0;
  }
}
