.containerTitle{
  margin-bottom: 10px;
  .title{
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    text-align: justify;
  }
  .more{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a{
      font-size: 14px;
      line-height: 22px;
      color:rgb(var(--primary-6));
      //color: #004fc4;
    }
  }

}
