.postsPage{
  margin: var(--padding-small) var(--padding-large);
  .posts{
    border-radius: var(--border-radius-small);
    background: #fff;
    :global(.arco-tabs-header-size-large.arco-tabs-header-nav-line .arco-tabs-header-title){
      font-size: 16px;
    }
  }
}
