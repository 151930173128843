.title{
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  padding-left: 80px;
}

.loginBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wechatLogo{
  width: 20px;
  margin-right: 3px;
  margin-bottom: -5px;
}
.wexinBtn{
  background: #07c160 !important;
  color: #fff !important;
  width: 100%;
}
