.about{
  background: #fff;
  padding: var(--padding-middle);
  align-items: center;
  justify-content: center;
  .logo{
    width: 150px;
    height: 150px;
    margin-bottom: var(--padding-middle);
  }
  .box{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.6;
    font-size: 16px;
  }
  .link{
    color: #1890ff;
    text-decoration: underline;

  }
}
