.postList{
  background: #fff;
  padding: 0 20px;
  //height:500px;
  :global(.arco-list-item-action) {
    justify-content:flex-end;
  }
  :global(.arco-list-item-extra-content) {
    position: absolute;
    right: 40px;
  }
  :global(.arco-list-item-action .arco-icon) {
    margin-right: 4px;
  }
  :global(.arco-list-footer){
    display: flex;
    justify-content: center;
  }


  .item{
    padding: 20px 0;
    border-bottom: 1px solid var(--color-fill-3);
  }
  .date{
    font-size: 13px;
    color: #999;
  }
  .content{
    text-decoration: none;
    .link{
      position: absolute;
      padding: 5px;
      height: auto;
      margin-top: -2px;
    }
  }



}

