.commentWrap{
  //position: relative;
  :global(.arco-comment-title-align-right){
    display: block;
  }
  :global(.arco-comment-content){
    white-space: break-spaces;
  }

  .action {
    padding: 0 5px;
    line-height: 24px;
    border-radius: 2px;
    background: transparent;
    transition: all 0.1s ease;
    color: var(--color-text-1);
    cursor: pointer;
    display: inline-block;

  }
  .content{
    text-decoration: none;
    .link{
      position: absolute;
      padding: 5px;
      height: auto;
      margin-top: -2px;
    }
  }
  .hoverable{
    &:hover {
      background: var(--color-fill-3);
    }
  }
  .feedback{
    float: right;
    margin-top: -5px;
  }
  .from{
    //background: #eee;
    color: var(--color-text-3);
    float: left;
    margin-top: -20px;
    margin-left: 52px;
    //bottom: 2px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
  }

}
