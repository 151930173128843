.qrCard{
  background: #fff;
  min-width: 260px;
  border-radius: var(--border-radius-small);
  .img{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
  }

}
