.home {
  margin: var(--padding-small) var(--padding-large);
  //background: #999;
  .card {
    margin-bottom: 30px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 4px 10px rgb(var(--gray-5));
    }
    .cover {
      width: 100%;
      padding-bottom: 60%;
      background-size: cover;
      background-position: center center;
    }
  }
}
.skeleton {
  background: #fff;
  margin: 0 var(--padding-large);
}
