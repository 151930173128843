.achievement {
  background: #fff;
  border-radius: var(--border-radius-small);
  .achievement_title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .line {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 10px 0;
  }
  .icon {
    font-size: 20px;
    margin-right: 10px;
  }
  .helper {
    color: #888;
    margin-left: 10px;
    margin-bottom: -2px;
  }
  .btnGroup{
    display: flex;
  }
  .btn {
    width: 100%;
  }
  .btn:nth-of-type(1) {
    margin-right: 10px;
  }
  .btn:nth-of-type(2) {
    margin-left: 10px;
  }
}
