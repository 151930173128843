.register{
  background: #fff;
  display: flex;
  justify-content: center;
  padding: var(--padding-middle);
  width: 70%;
  margin: 15px auto;
  min-height: 420px;
  .container{
    display: flex;
    width: 100%;
    .left{
      width: 50%;
      background-image: url('https://images.redream.cn/upic/2019/20220611192411-Secure (1).png');
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .right{
      width: 50%;
      margin-left: 10px;
      border-left: solid 1px #999;
      padding-left: 20px;
      a{
        color: dodgerblue;
      }
    }

  }
}
