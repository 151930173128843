.category{
  margin: var(--padding-small) var(--padding-large);

  .pageHeader{
    //background: #fff;

    :global(.arco-page-header-head-wrapper){
      padding-left: 0;
    }
    &:global(.arco-page-header-with-breadcrumb){
      padding-top: 0;
    }
  }
}
