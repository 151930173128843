.footer{
  //height: 200px;
  background: rgb(var(--primary-6));

  padding: var(--padding-middle) var(--padding-middle);

  display: flex;
  justify-content: center;
  .title{
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .info{
    display: block;
    justify-content: center;
    align-items: center;
    margin:10px  50px;
    //background: #789;
    &:first-of-type{
      margin-right: 200px;
    }
  }
  a{
    color: rgba(255,255,255,0.8);
    cursor:pointer;
    span{
      display:inline-block ;
      width: 120px;
      text-align: justify;
      white-space: nowrap;
    }
    //width: 300px;
    &:hover{
      color: rgba(255,255,255,1);
    }
  }

}
