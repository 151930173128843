.actionBar{
  display:flex;
  justify-content:flex-end;

  :global( .arco-icon) {
    margin-right: 4px;
  }

  .action {
    padding: 0 5px;
    line-height: 24px;
    border-radius: 2px;
    background: transparent;
    transition: all 0.1s ease;
    color: var(--color-text-1);
    cursor: pointer;
    display: inline-block;
    &:hover {
      background: var(--color-fill-3);
    }
  }

}
