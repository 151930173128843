.card{
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: var(--border-radius-small) !important;
  &:hover{
    box-shadow: 0 4px 10px rgb(var(--gray-5));
  }
  .cover{
    width: 100%;
    padding-bottom: 60%;
    background-size: cover;
    background-position: center center;
  }
  :global(.arco-card-body){
    padding: 16px 14px;
  }
  .info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-size: 1rem;
      overflow:hidden; //超出的文本隐藏
      text-overflow:ellipsis; //溢出用省略号显示
      white-space:nowrap; //溢出不换行
    }
    .btn{
      font-size: 1rem;
      padding: 0 12px;
    }
  }
}
