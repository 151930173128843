.loginContainer{
  margin: auto;
  width: 280px;
  height: 250px;
  .tips{
    font-size: 12px;
    //color: #333;
    display: flex;
    justify-content: center;
    margin-bottom: -10px;
    margin-left: 20px;
  }
}
