:root {
  --padding-large: 100px;
  --padding-middle: 50px;
  --padding-small: 20px;

  --bg-color-light: #f1f1f1;
  --bg-color-white: #fff;

  --font-size-mini: 12px;
  --font-size-small: 14px;

  --tip-color: #86909c;
  --border-radius-small: 3px;
  --border-radius-middle: 7px;

  --highlight-color:#ea4335;
}

body {
  background: var(--bg-color-light);
  min-width: 1096px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (-webkit-min-device-pixel-ratio: 2) {
  /* 根据需要调整样式 */
  html {
    font-size: 16px; /* 调整字体大小 */
  }
}
// 低分辨率屏幕，字体显示小一些
@media (-webkit-min-device-pixel-ratio: 1) and (-webkit-max-device-pixel-ratio: 1.99){
  /* 根据需要调整样式 */
  html {
    font-size: 14px; /* 调整字体大小 */
  }
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}
.arco-avatar-circle {
  border: solid 2px #aaa;
}

.arco-list-item-meta-content,
.arco-list-item-meta-description {
  min-width: 0;
  width: 100%;
}
.arco-comment-inner {
  min-width: 0;
}
.arco-avatar {
  background-color: #fff !important;
}
.arco-badge-number {
  margin-top: 5px;
}

.arco-comment:not(:first-of-type),
.arco-comment-inner-comment {
  margin-top: 0px !important;
}
// 解决动画被overflow 裁剪问题
.arco-spin-children {
  position: static !important;
}
.arco-tabs {
  overflow: auto !important;
  position: static !important;
}
.arco-avatar-text {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.highlight{
  color: var(--highlight-color);
}

@arcoblue-6: #54317D;