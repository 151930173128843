.author {
  flex: 1;
  min-width: 0;
  .small {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 5px;

    .left {
      display: flex;
      align-items: center;
      min-width: 0;
      .box {
        display: flex;
        flex-direction: column;
        min-width: 0;
        margin-left: 5px;
        .name {
          color: #333;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .intro {
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          min-width: 0;
        }
      }
      .avatar {
      }
    }
    .right {
    }
  }

  .middle {
    .avatarWrap {
      display: flex;
      .info {
        margin-left: 10px;
        .name {
          font-size: 18px;
          color: #333;
        }
      }
      .avatar {
      }
    }
    .introduce {
      margin: var(--padding-small) 0;
    }
    .btnGroup {
      margin: var(--padding-small) 0;
      button {
        border-radius: var(--border-radius-small);
      }
      .follow {
        width: 100%;
      }
    }
  }
}
