.messageBlock{
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  .time{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-mini);
    color: var(--tip-color);
    margin-bottom: 10px;
  }
  .reason{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-small);
    color: var(--tip-color);
    margin-top: 0.1rem;
  }
}

.message{
  display: flex;
  justify-content: flex-start;
  .warn{
    margin-top: 8px;
    font-size: 24px;
    color: rgb(245, 63, 63);
  }
}

.avatar{
  flex-shrink: 0;
}

.isSelf{
  flex-direction: row-reverse;
}

.content{
  max-width: 80vw;
  padding: 10px;
  border-radius: 10px;
  background-color: #f1f1f1;
  position: relative;
  margin: 0 0.2rem 0 0.2rem ;
  word-break: break-all;
}
.content img{
  max-width: 5rem;
  width: 5rem;
  height: 5rem;
}
.isSelf .content{
  background-color: #E8F3FF;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #f1f1f1;
  border-bottom: 10px solid transparent;
  position: absolute;
  left: -10px;
  top: 10px;
}


.isSelf .triangle{
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #E8F3FF;
  border-bottom: 10px solid transparent;
  position: absolute;
  right: -10px;
  top: 10px;

  left: auto;
  border-right: none;
}


